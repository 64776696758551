import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { navigate } from 'gatsby-link';
import Cookies from 'js-cookie';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const validateToken = () => Cookies.get('Authorization');

export const logToSentry = (error) => {
  const email = sessionStorage.getItem('fm-user-email');
  Sentry.configureScope((scope) => scope.setLevel('Error').setUser({ email }));
  return Sentry.captureException(error);
};

const callApi = (method, url, params = null) => {
  const headers = {
    Authorization: null,
  };
  return new Promise((resolve, reject) => {
    try {
      const token = validateToken();
      if (token) headers.Authorization = `Bearer ${token}`;
      const response = params
        ? axios[method](url, params, { headers })
        : axios[method](url, { headers });
      resolve(response);
    } catch (err) {
      const { status } = err.response || {};
      if (status === 401) {
        navigate('/login');
        Cookies.remove('Authorization');
        Cookies.remove('user');
        logToSentry(err);
        reject(err);
      }
    }
  });
};

export const callGetApi = (url) => callApi('get', url, null);
export const callDeleteApi = (url) => callApi('delete', url, null);
export const callPostApi = (url, params) => callApi('post', url, params);
export const callPatchApi = (url, params) => callApi('patch', url, params);
